<template>
  <div class="404">
    <h1>404 Not Found</h1>
    <p>コンテンツが見つかりません</p>
    <p><a href="/">トップページに移動</a></p>
  </div>
</template>
<style scoped>
a{
  color:var(--linkColor);
}
</style>